.note-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
}

.practitioner-title {
    display: flex;
    flex-direction: column;
}

.select-submit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-container {
    flex: 1;
    margin-right: 10px;
    /* Adjust as needed */
}

.btn-wrap-assign {
    flex: 0;
    margin-top: 30px;
}

