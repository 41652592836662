.react-date-picker-wrapper .react-date-picker {
 display: flex;
}
.react-date-picker .react-date-picker__wrapper {
 border: 1px solid #c7c7c7;
 border-radius: 10px;
 background-color: transparent;
 padding: 0 9px;
}
.react-date-picker .react-date-picker__wrapper .react-date-picker__inputGroup input {
 border: none;
 border-radius: 0;
 padding: 0;
}

.date_picker_block .react-date-picker__wrapper .react-date-picker__inputGroup select {
    width: 33px;
    height: 18px;
    border: none;
    margin-right: -1px;
}

form.border-fields .field-wrap .react_date_picker select {
    background-image: none;
    padding-right: 1px;
}