.header-wrap {
 display: flex;
 display: -ms-flexbox;
 align-items: center;
 background: #ffffff;
 box-shadow: 0px 4px 11px rgb(0 0 0 / 5%);
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 9;
 height: 76px;
}

.header-body {
 display: flex;
 display: -ms-flexbox;
 align-items: center;
 justify-content: space-between;
 width: calc(100% - 300px);
 padding: 20px 25px;
}
.header-body .title {
   font-size: 20px;
   font-weight: 500;
}
.header-wrap .right-wrap {
 display: flex;
 display: -ms-flexbox;
 align-items: center;
}

.header-wrap .logo img {
 width: 180px;
}

.header-wrap .logo {
 width: 300px;
 border-right: 1px solid #bfbfbf;
 padding: 9px 25px;
 cursor: pointer;
}
.header-wrap .logo1 {
    width: 120px;
    margin-left: 20px;
    height:70px
   }

.header-wrap .logo1 img {
   width: 100%;
   height: 100%;
   object-fit: contain;
   display: block;
}

form.search-wrap input {
 background-color: #f6f6f6;
 border-radius: 10px;
 border: 0;
 font-weight: 400;
 font-size: 18px;
 color: rgba(0, 0, 0, 0.37);
 background-repeat: no-repeat;
 background-size: auto;
 background-position: calc(100% - 15px) center;
 height: 50px;
 width: 380px;
}

.user-Management-header form.search-wrap input {
   width: 100%;
   height: 100%;
}

form.search-wrap input:focus {
 box-shadow: none;
 background-color: #f6f6f6;
}

.user-dropdown {
 margin-left: 20px;
}

.user-dropdown .dropdown-toggle {
 background: transparent !important;
 border: 0;
 padding: 0;
 display: flex;
 display: -ms-flexbox;
 align-items: center;
}

.user-dropdown button .user-name {
 background: #f6f6f6;
 border-radius: 50px;
 width: 56px;
 height: 56px;
 border: 0;
 margin-right: 10px;
 display: flex;
 display: -ms-flexbox;
 align-items: center;
 justify-content: center;
 font-weight: 500;
 font-size: 25px;
 color: rgba(0, 0, 0, 0.36);
 overflow: hidden;
}

.user-dropdown button .user-name img {
   width: 100%;
   height: 100%;
   display: block;
   object-fit: contain;
}

.user-dropdown button:after {
 display: none;
}

.user-dropdown .dropdown-menu {
 background: #ffffff;
 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 border-radius: 18px;
 border: 0;
 min-width: 150px;
 padding: 5px 16px;
}

.user-dropdown .dropdown-menu .dropdown-item {
 border-bottom: 1px solid rgba(0, 0, 0, 0.19);
 padding: 18px 0;
 text-align: center;
 font-weight: 500;
 font-size: 20px;
 background: transparent;
}

.user-dropdown .dropdown-menu .dropdown-item:last-child {
 border: 0;
}

.user-dropdown .dropdown-menu .dropdown-item:hover {
 color: #005da8 !important;
}

.toggle-menu {
 margin-right: 8px;
 display: none;
}

/* responsive start */
@media (max-width: 1600px) {
 form.search-wrap input {
  font-size: 16px;
  height: 42px;
 }

 .header-body {
  padding: 10px 25px;
 }

 .user-dropdown .dropdown-toggle img {
  width: 14px;
 }

 .user-dropdown button .user-name {
  font-size: 22px;
 }

 .header-wrap .noti-icon img {
  width: 22px;
 }

 .user-dropdown .dropdown-menu .dropdown-item {
  font-size: 18px;
  padding: 14px 0;
 }
}

@media (max-width: 1400px) {
 form.search-wrap input {
  font-size: 14px;
  height: 40px;
 }

 .header-wrap .logo img {
  width: 150px;
 }

 .header-body {
  padding: 10px 20px;
 }

 .user-dropdown button .user-name {
  width: 45px;
  height: 45px;
  font-size: 20px;
 }

 .user-dropdown .dropdown-toggle img {
  width: 12px;
 }

 .header-wrap .noti-icon img {
  width: 20px;
 }

 .user-dropdown .dropdown-menu .dropdown-item {
  font-size: 16px;
  padding: 10px 0;
 }

 .user-dropdown .dropdown-menu {
  min-width: 110px;
 }

 .header-wrap .logo {
  width: 250px;
 }
 
 .header-body {
  width: calc(100% - 250px);
 }
}

@media (max-width: 1200px) {
 .toggle-menu {
  display: block;
 }
}

@media (max-width: 991px) {
 form.search-wrap input {
  width: 260px;
 }

 .header-wrap .noti-icon img {
  width: 16px;
 }

 .user-dropdown button .user-name {
  width: 40px;
  height: 40px;
  font-size: 16px;
  margin-right: 5px;
 }

 .user-dropdown .dropdown-toggle img {
  width: 8px;
 }

 .header-body {
  padding: 5px 15px;
 }

 .header-wrap .logo img {
  width: 120px;
 }
 .header-wrap .logo1 img {
    width: 80px;
   }
 .header-body {
  width: 100%;
 }
}

@media (max-width: 767px) {
 form.search-wrap input {
  width: 160px;
  background-size: 12px;
 }

 .header-wrap .noti-icon img {
  width: 14px;
 }

 .user-dropdown button .user-name {
  width: 35px;
  height: 35px;
  font-size: 14px;
 }

 .user-dropdown .dropdown-menu .dropdown-item {
  font-size: 13px;
  padding: 5px 0;
 }

 .user-dropdown .dropdown-menu {
  min-width: 85px;
 }
}
