.signature-modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .signature-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
  }
  
  /* .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  } */

 

  .close {
    position: relative;
    top: 10px;
    left: 485px; 
    cursor: pointer;
    font-size: 35px;
      
  }
  
  