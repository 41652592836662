.slotStyle {
 word-wrap: break-word;
 align-items: center;
 border: 1px solid rgba(0, 0, 0, 0.15);
 border-radius: 9px;
 color: #214469;
 display: flex;
 -webkit-filter: drop-shadow(0 5px 10px rgba(44, 121, 202, 0.05));
 filter: drop-shadow(0 5px 10px rgba(44, 121, 202, 0.05));
 font-size: 12px;
 font-weight: 400;
 line-height: 1.3;
 margin-right: 10px;
 padding: 6px 10px;
 text-align: center;
 transition: 0.2s;
 cursor: pointer;
 justify-content: center;
 width: fit-content;
}

.searchSelectLabel{
    font-size: 18px;
}
.search-select {
    height: 42px;
}

.search-select .select__control {
    height: 60px;
    border-radius: 10px;
}

.search-select .select__control .select__input {
    height: 100% !important;
}

.search-select .select__menu .select__menu-list .select__option {
    text-transform: capitalize;
}

.upload-doc {
    margin-top: 45px;
}

.slotStyle:hover {
 background-color: #6c757d38 !important;
}

.requiredField {
    color: #ec1422;
}

.header-text {
    font-weight: 100;
    font-size: 25px;
    color: #0064a6;
}

.label-grey {
    color: #7b7c7d;
}

.col-section {
    margin-top: 15px;
}
.dashboard .heading-wrap h1{
    font-size: 22px;
}

.dash-buttons{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.dash-buttons .btn-group button {
    font-weight: 500;
    font-size: 18px;
    color: #696969;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    margin: 3px;
    margin-right: 8px;
    padding: 9px 5px;
}
/* .search-select .select__control {
    height: 54px;
} */
.dash-buttons .btn-group {
    background: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
}

.dash-buttons .btn-group button:last-child {
    margin-right: 3px;
}

.dash-buttons .btn-group button.active,
.dash-buttons .btn-group button:hover {
    background: #e9f5ff;
    color: #005da8;
}

.btn.active.btn-secondary{
    font-size: 15px;
    padding: 8px;
}

.dash-buttons .btn.btn-secondary{
    font-size: 15px;
    padding: 8px;
    white-space: nowrap;
}
.dash-buttons .custom-btn.btn.btn-primary{
    font-size: 14px;
    padding: 8px;
    white-space: nowrap;
}
.me-2.form-control{
    font-size: 14px;
}

.search-block{
    display: flex;
}


.search-block .search-wrap{
    width : 380px;
    max-width: 100%;
}

.dash-buttons form.search-wrap input {
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 1600px){
    .search-select .select__control {
        height: 50px;
    }
}
@media screen and (max-width: 1400px){
    form.common-form.border-fields .searchSelectLabel {
        font-size: 14px;
    }
    .search-select .select__control {
        height: 54px;
    }
    
}

@media screen and (max-width: 1208px){
    .dash-buttons {
        flex-direction: column;
        gap: 15px;
    }
    .dash-buttons .custom-btn.btn.btn-primary{
        padding: 10px;
    }
    .dash-buttons .btn.btn-secondary{
        padding: 10px;
    }
}
@media screen and (max-width: 635px){
   .dash-buttons .btn-group {
        flex-wrap: wrap;
    }
    .dash-buttons .search-block{
        flex-direction: column;
        gap: 10px;
    }
    .dash-buttons input.me-2.form-control{
        width: 100%;
    }
}