ul.group-wrap {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 15px;
}

ul.group-wrap li {
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 40px 40px 20px;
    margin-bottom: 30px;
    position: relative;
    padding-right: 240px;
}

.group-wrap .custom-btn {
    width: auto;
}

ul.group-wrap li h4 {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 10px;
}

ul.group-wrap li p {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
}

.pat-wrap {
    background: rgba(0, 93, 168, 0.06);
    border-radius: 10px;
    width: auto;
    padding: 12px 15px;
    margin-bottom: 25px;
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

.group-wrap .pat-wrap a {
    width: auto;
    padding-left: 10px;
    font-weight: 400;
    color: #005DA8;
}

.group-wrap .custom-btn.add-patient {
    position: absolute;
    top: 0;
    right: 0;
    margin: 25px 20px 0 0;
    width: 220px;
}

/* pat-detail table outer style */
.outer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.outer-table-card {
    margin-top: 35px;
    padding: 28px 28px 0;
}

.outer-table-card .pagination-wrap {
    margin: 0 -28px;
}

.outer-header h3 {
    margin: 0;
    font-weight: 500;
    font-size: 25px;
}

.outer-table-card .table-wrap {
    margin: 20px 0;
}
/* style ends */

.group-data{
    height: 20px;
width: 436px;
left: 372px;
top: 285px;
border-radius: nullpx;

}
.margin-bottom-16{
    margin-bottom: 16px;
}
.checkBoxThreshold{
    float: left;
    width:50%;
}
.checkBoxThresholdInput{
    float: left;
    width:50%;
    height: 20px!important;
    margin-left: 0px !important;
}
.heightcheck{
    height: 20px!important;
}
.margin-10{
    margin-right: 10px
}
/* responsive start */
@media (max-width: 1600px) {
    ul.group-wrap li {
        padding: 30px 30px 20px;
    }
    
    ul.group-wrap li h4 {
        font-size: 22px;
    }
    
    ul.group-wrap li p {
        font-size: 15px;
    }
    
    .group-wrap .custom-btn.add-patient {
        width: 200px;
    }
    
    .pat-wrap {
        font-size: 15px;
        margin-bottom: 20px;
    }

    .outer-header h3 {
        font-size: 22px;
    }

    ul.group-wrap li {
        padding-right: 220px;
    }
}

@media (max-width: 1400px) {
    ul.group-wrap li {
        padding: 20px;
    }
    
    .group-wrap .custom-btn.add-patient {
        width: 180px;
    }
    
    ul.group-wrap li h4 {
        font-size: 20px;
        margin-bottom: 5px;
    }
    
    ul.group-wrap li p {
        font-size: 14px;
    }

    .outer-header h3 {
        font-size: 20px;
    }

    .outer-table-card {
        margin-top: 20px;
        padding: 20px 20px 0;
    }

    ul.group-wrap li {
        padding-right: 200px;
    }
}
.data-placement {
    display: flex;
    flex-wrap: wrap;
}
.data-placement p {
    margin-right: 25px;
}
@media (max-width: 1200px) {
}

@media (max-width: 991px) {
    .group-wrap .custom-btn.add-patient {
        width: 150px;
    }
    ul.group-wrap li {
        padding-right: 170px;
    }
}

@media (max-width: 767px) {
    .outer-header {
        display: block;
    }
}