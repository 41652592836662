.view-service-request-container {
    margin: 0 auto;
    padding: 10px 10px 20px 10px;
    border-radius: 10px;
  }

  .header{
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 10px 10px 20px 10px;
    border-radius: 10px;
  }
  
  .btn-box {
    margin-top: 10px;
  }
  
  .dataBox img {
      vertical-align: middle;
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
  }
  
  .info-wrapper span {
    color: #07384e;
  }
  
  .info-item{
    margin-left: 20px;
  }
  
  .sign-wrapper p {
    color: #07384e;
  }
  
  .c-black-8 {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  
  .c-black-7 {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  
  .c-black-6 {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  
  .c-black-5 {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  
  .m-gap {
    gap: 20px;
  }
  
  .customCheckBox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 12px;
  }
  
  .form-check-input:disabled {
    border-color: black;
  }
  
  .form-check-input:disabled {
    opacity: 1 !important;
  }
  
  .white-space {
    white-space: nowrap;
  }

  .label-grey {
    color: #7b7c7d;
    font-size: 15px;
}

.border-left {
  border-left: 3px solid #f3f3f3;
}

.header {
  border: 3px solid #f3f3f3;
  box-shadow: 0 0 3px #f3f3f3;
}

/* .header div {
  color: #8b8b8b;
} */

.header h6 {
  font-size: 15px;
  color: #8b8b8b;

}

.header .user-name-title {
  color: #333;
  word-break: break-all;
}

.heading-wrap  button {
  padding: 10px 20px;
  font-size: 15px;
}

.accordion-view-block {
  border: 3px solid #f3f3f3;
  border-radius: 3px;
}

.accordion-view-block .header-accordion {
  background-color: #f3f3f3;
  color: #333;
  font-size: 14px;  
}

.accordion-view-block .body-accordion {
  font-size: 14px;
  color: #8b8b8b;
}

.additional-info-block {
  border: 3px solid #f3f3f3;
  border-radius: 8px;
}

.additional-info-block .title-header h5 {
  font-size: 16px;
  color: #333;
}

.additional-info-block .body-info p {
  font-size: 14px;
}

.additional-info-block .body-info .info-title {
  color: #8b8b8b;
  margin: 0 0 5px;
}

.additional-info-block .body-info .info-details {
  color: #333;
}

.additional-info-block .body-info .type-list-block span {
  border-radius: 3px;
  padding: 2px 10px;
  font-size: 14px;
  background-color: #f3f3f3;
  color: #2bbc6e;
  margin: 0 15px 0 0;
  font-weight: 600;
}

.additional-info-block .body-info .type-list-block span:last-child {
  margin: 0;
}

.circular-container {
  border-radius: 50%;
  background: rgba(250, 250, 250, 0.9);
  width: 83px;
  margin: 0 auto;
  height: 83px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.circular-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1100px) {
  .header .user-name-title {
    margin-left: -19px;
    word-break: break-all;
  }
}