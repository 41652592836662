.expiration-days-input {
    width: 100px;
}

.expiration-days-input .field-wrap {
    margin: 0 !important;
}
@media (max-width:992px){
    .popup-org .modal-dialog {
        width: 100% !important;
        max-width: 90%;
    }
}

.org-img-error{
    font-size: 14px;
    color: #dc3545;
    padding-top: 5px;
    margin-top: -24px;
    margin-bottom: 20px;
}