.detail-listing {
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow: auto;
    position: relative;
}

.detail-chart-wrap {
    display: flex;
    display: -ms-flexbox;
    margin-bottom: 24px;
    width: 100%;
}

.chart-wrap {
    width: 40%;
    padding-left: 40px;
}
.profile-new-img-wrap {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
}

.detail-chart-wrap .custom-card {
    width: 60%;
}

.chart-body {
    background: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
}

.chart-body h3 {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    padding: 20px 20px 0;
}

.chart-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    flex-wrap: wrap;
}

.chart-head select {
    width: 148px;
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    padding: 8px;
}

.profile-wrap-new {
    
    align-items: start;
    height: 100%;
}

.name-header {
    font-weight: 500;
    font-size: 26px;
    margin: 0;
}

.profile-wrap-new .img-text {
    padding: 0 20px;
}

.profile-wrap-new .img-wrap {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
}

.detail-wrap-new {
    padding-left: 30px;
    padding-right: 20px;
    width: calc(100% - 200px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detail-wrap-new ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    gap: 14px;
}

.detail-wrap-new .bottom-pat-report {
    margin-bottom: 40px;
}

.detail-wrap-new ul.top-report {
    margin-bottom: 60px;
}

.profileText {
    padding: 10px 0 5px 0
}

.profileText > div > div , .profileUpper {
    font-weight: 400;
    font-size: 18px;
}

.tabs-btn-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
}

/* .tabs-btn-wrap>div {
      width: 50%;
  } */
.tabs-btn-wrap .tab-wrap {
    width: 58%;
}

.tabs-btn-wrap .btn-wrap {
    width: 42%;
}

.tabs-btn-wrap .nav {
    box-shadow: 0px 0px 7px rgb(0 0 0 / 14%);
    border-radius: 10px;
    padding: 3px;
    /* margin-right: 40px; */
    justify-content: flex-start;
    padding: 10px;
}

.chart-head .btn-group button {
    font-weight: 500;
    font-size: 14px;
    color: #696969;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px !important;
    margin: 3px;
    margin-right: 8px;
    padding: 9px 5px;
}

.chart-head .btn-group {
    background: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    margin-right: 10px;
}

.chart-head .btn-group button:last-child {
    margin-right: 3px;
}

.chart-head .btn-group button.active,
.chart-head .btn-group button:hover {
    background: #e9f5ff;
    color: #005da8;
}

.tabs-btn-wrap .nav .nav-link {
    background: #ffffff;
    border-radius: 10px;
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    color: #696969;
    width: 100%;
    text-align: center;
}

.tabs-btn-wrap .nav .nav-item {
    flex: 1;
    margin-right: 10px;
}

.tabs-btn-wrap .nav .nav-link.active,
.tabs-btn-wrap .nav .nav-link:hover {
    background: #e0f1ff;
    color: #005da8 !important;
}

.tabs-btn-wrap .btn-wrap .custom-btn {
    /* width: auto; */
    font-weight: 500;
    font-size: 18px;
    /* height: 88px; */
    /* width: 50%; */
    padding: 15px 20px;
}

.tabs-btn-wrap .btn-wrap {
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
    align-items: center;
}

.profileText > div > div:nth-child(odd), .profileUpper {
    color: rgba(0, 0, 0, 0.58);
}

.profileUpper span {
    color: #121f48;
}
.profileText > div > div:nth-child(even) {
    display: block;
    color: #121f48;
    padding-bottom: 5px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}


.detail-wrap-new ul.pat-report li span {
    color: rgba(0, 0, 0, 0.58);
}

.detail-wrap-new .status-wrap {
    display: inline-block;
    width: auto;
    margin-left: 10px;
}

.detail-wrap-new p {
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.58);
}

.tabs-btn-wrap .nav .nav-item:last-child {
    margin-right: 0;
}

/* .tab-wrap .tab-content {
      width: calc(100% - 55px);
      position: absolute;
  } */

/* detail table style changes */
.table-wrap.diff-head table th[colspan="2"] {
    border: 0;
}

.table-wrap.diff-head table thead tr:nth-child(2) th {
    padding: 0.5rem 0.5rem;
    height: auto;
    font-weight: 400;
}

.table-wrap.diff-head table th {
    height: auto;
    font-weight: 500;
}

.circle-wrap {
    width: 35px;
    height: 35px;
    background: #d5dbd8;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.circle-wrap.green {
    background: #06b155;
}

.circle-wrap.yellow {
    background: #ffc000;
}

.circle-wrap.red {
    background: #ff0000;
}

.table-wrap table td.text-color {
    color: #005da8;
}

/* end table style */

/* days slider style start */
.day-slider .slick-track {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
}

.mnth-date-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    margin: 30px 0;
}

.mnth-date-wrap select {
    border: 1px solid rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 5px 10px rgba(44, 121, 202, 0.05));
    border-radius: 9px;
    width: 160px;
    margin-right: 40px;
    font-size: 17px;
    color: #214469;
}

.day-slider .slick-list {
    width: 100%;
    overflow: hidden;
}

.day-slider {
    width: calc(100% - 200px);
}

.day-slider .slick-slider {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    width: 100%;
}

.day-slider .slick-list button {
    border: 1px solid rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 5px 10px rgba(44, 121, 202, 0.05));
    border-radius: 9px;
    background: #fff;
    padding: 6px 10px;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    color: #214469;
    margin-right: 10px;
    transition: 0.2s;
}

.day-slider .slick-slider .slick-arrow:hover {
    opacity: 0.6;
}

.day-slider button.today,
.day-slider .slick-list button:hover {
    background: #005da8;
    color: #fff;
}

.day-slider .slick-slider .slick-arrow {
    font-size: 0;
    position: relative;
    border: 0;
    padding: 0;
    width: 50px;
    height: 45px;
}

.day-slider .slick-slider .slick-prev {
    background-color: #eaf6ff;
    border-radius: 9px;
    margin-right: 10px;
    /* background-image: url(../../assets/images/arrow-right.png);
      background-repeat: no-repeat;
      background-size: auto;
      background-position: center; */
}

.day-slider .slick-slider .slick-next {
    background-color: #eaf6ff;
    border-radius: 9px;
    margin-left: 10px;
}

.day-slider .slick-slider .slick-prev::before {
    content: "←";
    font-family: unset !important;
    font-size: 26px;
    color: #005da8;
    font-weight: 400;
}

.day-slider .slick-slider .slick-next::before {
    content: "→";
    font-family: unset !important;
    font-size: 26px;
    color: #005da8;
    font-weight: 400;
}

/* slider style ends */

/* madication table style start */
.medi-wrap {
    display: flex;
    align-items: center;
}

.medi-wrap .img-wrap {
    width: 50px;
    height: 50px;
    background: rgba(224, 235, 244, 0.61);
    border-radius: 50%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.medi-wrap .text-wrap h4 {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
}

.medi-wrap .text-wrap p {
    font-weight: 500;
    font-size: 14px;
    color: #8e8e8e;
    margin: 0;
}

.medi-wrap p span {
    position: relative;
    display: inline-block;
    padding-left: 10px;
    margin-left: 8px;
}

.medi-wrap p span::before {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    background: #8e8e8e;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.medi-status {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
}

.medi-status .left {
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #214469;
}

.medi-status .right {
    margin-right: 40px;
    background: #ffffff;
    border: 0.850489px solid #c4cdea;
    border-radius: 11px;
    padding: 8px 20px;
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #214469;
}

.medi-status .right img {
    padding-right: 8px;
}

.medi-status .left img {
    padding-right: 5px;
}

.table-wrap.medication-wrap table tr:nth-child(2n) td {
    padding-left: 0.5rem;
}

.table-wrap.medication-wrap table tr:nth-last-child(2) td[rowspan="2"] {
    border-bottom: 0;
}

.table-wrap.medication-wrap table tr:hover td {
    background: transparent;
}

.table-wrap.medication-wrap table tr td[rowspan="2"] {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.medi-status .right:hover {
    background: #c4cdea;
}

.table-wrap .error-icon {
    margin-left: 16px;
}

.field-wrap input {
    font-size: 12px;
}

.field-wrap label {
    font-size: 12px;
}

.leftBorderHeading {
    border-left: 5px solid #0083c0;
    padding-left: 10px;
    font-weight: 600;
}

/* medication style end */

/* responsive start */
@media (max-width: 1600px) {
    .chart-wrap {
        padding-left: 25px;
    }

    .chart-head .btn-group button {
        font-size: 12px;
    }

    .chart-head select {
        font-size: 14px;
        width: 130px;
        padding: 8px;
    }

    .chart-head .btn-group button {
        padding: 8px 5px;
        margin-right: 5px;
    }

    .chart-head {
        margin-bottom: 10px;
    }


    .profileText > div > div, .profileUpper{
        font-size: 16px;
        line-height: 1.3;
    }

    .detail-wrap-new {
        padding-left: 20px;
        padding-right: 20px;
    }

    .name-header {
        font-size: 22px;
    }

    .detail-wrap-new p {
        font-size: 16px;
    }

    .chart-body h3 {
        font-size: 12px;
        padding: 12px 10px 0;
    }

    .tabs-btn-wrap .nav .nav-link {
        font-size: 16px;
    }

    .tabs-btn-wrap .btn-wrap .custom-btn {
        font-size: 16px;
    }

    .medi-wrap .text-wrap h4 {
        font-size: 16px;
    }

    .medi-wrap .text-wrap p {
        font-size: 13px;
    }

    .circle-wrap {
        font-size: 17px;
    }
}

@media (max-width: 1400px) {
    .tabs-btn-wrap .nav .nav-item {
        margin-right: 8px;
    }

    .tabs-btn-wrap .btn-wrap .custom-btn {
        font-size: 14px;
    }

    .chart-head .btn-group button {
        font-size: 10px;
    }

    .chart-wrap {
        padding-left: 20px;
    }

    .chart-head select {
        font-size: 12px;
        width: 120px;
    }


    .profile-wrap-new .img-wrap {
        width: 80px;
        height: 80px;
        margin-bottom: 12px;
    }

    .name-header {
        font-size: 18px;
    }

    .profileText > div > div, .profileUpper{
        font-size: 14px;
    }

    .detail-wrap-new p {
        font-size: 14px;
    }

    .tabs-btn-wrap .nav .nav-link {
        font-size: 14px;
    }

    .tabs-btn-wrap .btn-wrap .custom-btn img {
        width: 20px;
    }

    .mnth-date-wrap select {
        font-size: 14px;
        padding: 10px 8px;
    }

    .day-slider .slick-slider .slick-arrow::before {
        font-size: 20px;
        position: relative;
        top: -3px;
    }

    .day-slider .slick-list button {
        padding: 5px 8px;
    }

    .day-slider .slick-slider .slick-arrow {
        height: 43px;
        width: 45px;
    }

    .detail-chart-wrap {
        margin-bottom: 18px;
    }

    .detail-listing {
        padding: 25px 20px;
    }

    .mnth-date-wrap {
        margin: 20px 0;
    }

    .table-wrap {
        margin: 20px 0;
    }

    .medi-wrap .text-wrap h4 {
        font-size: 15px;
    }

    .medi-wrap .text-wrap p {
        font-size: 12px;
    }

    .medi-status .left {
        font-size: 13px;
    }

    .medi-status .right img {
        width: 28px;
    }

    .medi-status .right {
        padding: 6px 12px;
        font-size: 13px;
    }

    .medi-wrap .img-wrap {
        width: 40px;
        min-width: 40px;
        height: 40px;
    }

    .medi-wrap .img-wrap img {
        width: 22px;
    }

    .circle-wrap {
        font-size: 15px;
    }

    .table-wrap .error-icon {
        width: 16px;
        margin: -4px 0 0 10px;
    }

    /* .tab-wrap .tab-content {
          width: calc(100% - 40px);
      } */

    .medi-status .left img {
        width: 25px;
    }
}

@media (max-width: 1200px) {
    .tabs-btn-wrap .nav {
        margin-right: 20px;
    }

    .tabs-btn-wrap .btn-wrap .custom-btn:first-child {
        margin-right: 8px;
    }

    .chart-head {
        display: block;
    }

    .chart-head .btn-group {
        margin: 0 0 5px;
    }
}

@media (max-width: 991px) {
    .tab-wrap .tab-content {
        width: auto;
    }

    .medi-status .left {
        margin-right: 10px;
    }
}

@media (max-width: 767px) {
    .tab-wrap .tab-content {
        width: 94%;
    }

    .detail-chart-wrap {
        display: block;
    }

    .detail-chart-wrap .custom-card {
        width: 100%;
    }

    .chart-wrap {
        width: 100%;
        padding: 15px 0 0;
    }

    .profile-wrap-new {
        display: block;
    }

    .detail-wrap-new {
        width: 96%;
    }

    .detail-wrap-new .bottom-pat-report {
        margin: 0;
    }

    .tabs-btn-wrap {
        flex-direction: column-reverse;
    }

    .tabs-btn-wrap>div {
        width: 100%;
    }

    .tabs-btn-wrap .nav {
        margin: 0;
    }

    .tabs-btn-wrap .btn-wrap {
        margin-bottom: 10px;
    }

    .circle-wrap {
        font-size: 13px;
    }

    .mnth-date-wrap {
        display: block;
    }

    .mnth-date-wrap select {
        width: 100%;
        margin-bottom: 8px;
    }

    .day-slider {
        width: 100%;
    }
}

.chart-wrap .chat-date-blc {
    margin-bottom: 12px;
}

.tab-wrap .mnth-date-wrap .field-wrap {
    margin-right: 14px;
}

@media (max-width: 1155px) {
    .tabs-btn-wrap .nav {
        margin-right: 0;
    }
}

.dataCell {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
    cursor: pointer;
}

.sign-wrapper .sign-data {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.sign-wrapper .sign-data .dataBox {
    width: 100px;
    height: 70px;
    border-bottom: 1px solid #a7a7a7;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.serviceButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}