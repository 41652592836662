.address-details-block {
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: space-between;
}

.address-details-block .left-block {
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    padding: 10px 10px;
    width: calc(100% / 2 - 6px);
}

.address-details-block .right-block {
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    padding: 10px 10px;
    width: calc(100% / 2 - 6px);
}

.address-details-block .left-block .row:last-child .field-wrap {
    margin: 0;
}

.address-details-block .right-block .row:last-child .field-wrap {
    margin: 0;
}

.address-block-popup .row:last-child .col .col:last-child .field-wrap {
    margin-bottom: 0;
}

.address-block-popup .border-row {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 6px 0 10px;
}

.contact-block-popup .row:last-child .col .col:last-child .field-wrap {
    margin-bottom: 0;
}

.contact-block-popup .border-row {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 6px 0 10px;
}

@media screen and (max-width: 992px) {
    
    .address-details-block {
        flex-direction: column;
    }

    .address-details-block .left-block {
        margin: 0 0 20px;
    }

    .address-details-block .left-block, .address-details-block .right-block {
        width: 100%;
    }

    .address-details-block .left-block .row:last-child .field-wrap, .address-details-block .right-block .row:last-child .field-wrap {
        margin-bottom: 25px;
    }

    .address-details-block .left-block .row:last-child .col:last-child .field-wrap, .address-details-block .right-block .row:last-child .col:last-child .field-wrap {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .address-block-popup .row:last-child .col .col:last-child .field-wrap, .contact-block-popup .row:last-child .col .col:last-child .field-wrap {
        margin-bottom: 25px;
    }

    .address-block-popup .row:last-child .col:last-child .col:last-child .field-wrap, .contact-block-popup .row:last-child .col:last-child .col:last-child .field-wrap {
        margin-bottom: 0;
    }
}