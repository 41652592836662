.popup-org .days-row {
    margin-bottom: 25px;
    /* flex-direction: column; */
}  
.popup-org form.common-form .hours-operation-row .field-wrap {
    margin-bottom: 0;
}
.popup-org .days-label {
    height: 100%;
    display: flex;
    align-items: center;
}

.popup-org .box-wrap .top-wrap {
    margin: 0;
}

.add-org-wrap .days-row {
    margin-bottom: 0;
    /* flex-direction: column; */
}  
.add-org-wrap form.common-form .field-wrap {
    margin-bottom: 25px;
}
.add-org-wrap .days-label {
    height: 100%;
    display: flex;
    align-items: center;
}

.add-org-wrap .box-wrap .top-wrap {
    margin: 0;
}

@media (max-width: 1100px) {
    .popup-org .box-wrap .top-wrap .texts-wrap {
        font-size: 13px;
    }
    .add-org-wrap .box-wrap .top-wrap .texts-wrap {
        font-size: 13px;
    }
}

@media screen and (max-width: 1000px) {
    .add-org-wrap .hours-operation-row {
        flex-direction: column;
    }
}

@media screen and (max-width: 992px) {
    .popup-org .hours-operation-row {
        flex-direction: column;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .popup-org .hours-operation-row .col:last-child .input-label {
        display: none;
    }
    .add-org-wrap .hours-operation-row .col:last-child .input-label {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .popup-org .hours-operation-row .field-wrap label {
        margin-bottom: 5px;
    }
}