.questionnair-wrapper {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-top: 20px;
}

.head-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.head-wrap > span, .head-wrap > div {
    display: flex;
    align-items: center;
}

.head-wrap > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin: 0 10px;
}

.head-wrap > div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.head-wrap > span > svg, .head-wrap > div > img {
    width: 20px;
    height: auto;
}