/*@import url("https://fonts.cdnfonts.com/css/galano-grotesque-alt");*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

* {
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
}

.Toastify__toast-container,
.Toastify {
    z-index: 999999 !important;
}

#zmmtg-root {
    display: none;
    z-index: 99;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #121f48;
    font-weight: 400;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    color: #0055aa;
}

a:hover {
    color: #121f48 !important;
}

.program-conditions-table table tr td {
    padding: 0 0.5rem;
}

.ellipsis {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 10rem;
}

/* common-style for custom btn */
.custom-btn {
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    padding: 12px 20px;
    background: linear-gradient(90deg, #0083c0 0%, #005da8 100%);
    border-radius: 10px;
    border: 0;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    text-align: center;
}

.custom-btn:hover {
    background: #121f48 !important;
    color: #fff !important;
    border-color: #121f48 !important;
}

.white-btn {
    border: 1px solid #0080be;
    background: #fff;
    color: #0080be;
}

/* common-style for custom btn ends */

.body-outer {
    display: flex;
    display: -ms-flexbox;
    width: 100%;
    padding-top: 76px;
    /*flex-wrap: wrap;*/
    /*overflow: auto;*/
}

.body-outer .body-wrapper {
    flex: 1;
    overflow: auto;
}

/* common table listing page style start */
.heading-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 10px 0;
}

.heading-wrap h1 {
    font-weight: 500;
    font-size: 26px;
    color: #000000;
    margin: 0;
}

.heading-wrap.diff-style {
    justify-content: space-between;
}

.custom-card {
    background: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    padding: 10px;
}

section.common-listing {
    padding: 20px 35px 20px 35px;
    height: calc(100vh - 76px);
    overflow: auto;
}

section.dashboard {
    padding: 20px 35px 20px 35px;
    overflow: auto;
}

.table-wrap {
    margin: 30px 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.table-wrap table {
    margin: 0;
}

.table-wrap table th {
    background: rgba(0, 93, 168, 0.06);
    height: 60px;
    font-weight: 400;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.77);
}

.table-wrap table th,
.table-wrap table td {
    border-color: rgba(0, 0, 0, 0.15);
    vertical-align: middle;
}

.table-wrap table tr:last-child td {
    border-bottom: 0;
}

.table-wrap table td {
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    height: 80px;
}

.table-wrap table th:first-child,
.table-wrap table td:first-child {
    padding-left: 30px;
}

.table-wrap table td.name-text {
    font-weight: 500;
    white-space: nowrap;
}

.table-wrap table tbody tr:hover td {
    background-color: rgb(0 93 168 / 3%);
}

.pagination-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: space-between;
    background: rgba(142, 142, 142, 0.06);
    border-radius: 10px;
    padding: 12px 30px;
}

.pagination-wrap p {
    font-weight: 400;
    font-size: 18px;
    margin: 0;
    color: rgba(0, 0, 0, 0.71);
}

.pagination-wrap ul {
    margin: 0;
}

.pagination-wrap ul .page-link {
    background: transparent;
    border: 0;
    color: #696969;
    font-weight: 500;
    font-size: 18px;
}

.pagination-wrap ul .active .page-link {
    background: #005da8;
    width: 36px;
    height: 36px;
    color: white;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-wrap {
    background: rgb(144 149 146 / 10%);
    border-radius: 5px;
    text-align: center;
    width: 140px;
    padding: 2px 12px;
}

.status-wrap.normal {
    background: rgba(6, 177, 85, 0.1);
    color: #06b155;
}

.status-wrap.warning {
    background: rgba(255, 192, 0, 0.12);
    color: #ffc000;
}

.status-wrap.alerts {
    background: rgba(255, 0, 0, 0.09);
    color: #ff0000;
}

.status-td .status-wrap:last-child {
    margin-right: 40px;
}

td.status-td {
    width: 400px;
}

.action-wrap {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    justify-content: center;
}

.action-wrap a,
.action-wrap div {
    background: #ced1d3;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    text-align: center;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}

.action-wrap .view-btn {
    background: #005da8;
}

.action-wrap .success-btn {
    background: #06b155;
}

.action-wrap .delete-btn {
    background: #ff0000;
}

.action-wrap .note-btn {
    background: #368ace;
}

.action-wrap a:last-child {
    margin-right: 0;
}

.action-wrap a:hover {
    background: #121f48;
}

.heading-wrap form.search-wrap input {
    width: 560px;
}

li.next-arrow .page-link {
    background: url(assets/images/right-arrow.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right center;
    padding-right: 0;
}

li.next-arrow .page-link span,
li.prev-arrow .page-link span {
    opacity: 0;
}

li.prev-arrow .page-link {
    background: url(assets/images/right-arrow.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right center;
    transform: rotate(180deg);
    padding-left: 0;
}

.pagination-wrap ul .page-link:focus {
    box-shadow: none;
}

.pagination-wrap ul .next-arrow .page-link:hover,
.pagination-wrap ul .prev-arrow .page-link:hover {
    opacity: 0.6;
}

.pagination-wrap .page-item {
    margin-right: 10px;
}

.pagination-wrap .next-arrow {
    margin-right: 0;
}

/* common table listing page style ends */

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 1;
    -webkit-appearance: none;
}

input[type="date"] {
    background-image: url(assets/images/Calendar.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: calc(100% - 15px) center;
}

/* common dialog style start */
.custom-dialog .modal-content .btn-wrap {
    display: flex;
    display: -ms-flexbox;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
}

.custom-dialog .modal-content .btn-wrap .white-btn {
    margin-right: 20px;
}

.custom-dialog .modal-header {
    padding: 12px 30px;
}

.custom-dialog .modal-header .modal-title {
    font-weight: 600;
    font-size: 30px;
    color: #005da8;
}

.margin-bottom-4 {
    margin-bottom: 10px;
}

.custom-dialog .modal-header .btn-close {
    background-size: 14px;
}

.common-form .field-wrap input,
.common-form .field-wrap textarea,
.common-form .field-wrap select {
    background-color: #f6f6f6;
    border-radius: 10px;
    border: 0;
    margin-left: 0;
    height: 60px;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    padding: 5px 15px;
    resize: none;
}

.common-form .field-wrap textarea {
    height: 130px;
}

.common-form .field-wrap input:focus,
.common-form .field-wrap select:focus,
.common-form .field-wrap textarea:focus {
    outline: 0;
}

.custom-dialog .modal-content {
    background: #ffffff;
    box-shadow: 0px 26px 36px rgba(0, 0, 0, 0.13);
    border-radius: 24px;
}

.custom-dialog .modal-body {
    padding: 12px 30px 30px;
}

.custom-dialog .modal-header .btn-close:focus {
    box-shadow: none;
}

.custom-dialog .modal-body p {
    font-weight: 400;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.77);
    line-height: 1.3;
}

.custom-dialog .field-wrap {
    margin-bottom: 20px;
}

.custom-dialog .modal-xl .custom-btn {
    width: 260px;
}

.toggle-wrap {
    align-items: center;
}

.toggle-wrap .col:last-child {
    text-align: right;
}

/* dialog style ends */

/* diff action */
.action-wrap .light-blue {
    background: #e0f1ff;
}

.big-circle a {
    width: 50px;
    height: 50px;
}

.big-circle a img {
    width: auto;
}

.action-wrap .light-blue:hover {
    background: #a9cdeb;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 22px;
}

::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #ccc;
}

/* end scrollbar */

/* common form style start */
form.common-form .btn-wrap button {
    max-width: 220px;
}

form.common-form .btn-wrap .white-btn {
    margin-right: 20px;
}

.user-Management-header form.common-form .field-wrap{
    margin-bottom: 0px;
}

.user-Management-header form.common-form .field-wrap select{
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.17);
}

form.common-form .field-wrap {
    margin-bottom: 30px;
}


form.common-form .btn-wrap {
    margin-top: 10px;
}

form.border-fields .field-wrap input,
form.border-fields .field-wrap select,
form.border-fields .field-wrap textarea {
    border: 1px solid #c7c7c7;
    border-radius: 10px;
    background-color: transparent;
}

.error-text {
    font-size: 14px;
    color: #dc3545;
    padding-top: 5px;
}

/* common form ends */

.common-form .field-wrap label {
    font-weight: 400;
    font-size: 18px;
}

.sidebar-wrap span.dropdown-item-text {
    padding: inherit;
}

.sidebar-inner a {
    text-decoration: none;
    color: inherit;
}

.sidebar-inner a:hover {
    color: inherit !important;
}

.h-change .right-wrap {
    margin-left: auto;
}

/* custom-checkbox start */
.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px !important;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-checkbox .checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border: 1px solid #005da8;
    border-radius: 4px;
}

.patient-list.custom-checkbox .checkmark {
    top: -7px !important;
}

.custom-checkbox:hover input~.checkmark {
    background-color: #ccc;
}

.custom-checkbox input:checked~.checkmark {
    background-color: #ffffff;
}

.custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

.custom-checkbox .checkmark:after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid #005da8;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* custom-checkbox ends */

.check-select-wrap {
    max-width: 800px;
}

.check-select-wrap label.custom-checkbox {
    margin-top: 30px;
    margin-bottom: 50px !important;
}

.common-form .check-select-wrap select {
    margin-bottom: 20px;
    width: 180px;
}

/* view-case-manager style start */
.vd-profile-wrap {
    background: #ffffff;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.14);
    border-radius: 10px;
    display: flex;
    margin-bottom: 30px;
    width: fit-content;
}

.vd-profile-wrap .photo-side {
    border-right: 1px solid rgba(0, 0, 0, 0.14);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.vd-profile-wrap .other-detail {
    padding: 25px;
}

.vd-profile-wrap .img-wrap {
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 15px;
}

.vd-profile-wrap .other-detail .readonly input {
    padding: 0;
    border: 0;
    height: auto;
}

.vd-profile-wrap .other-detail .readonly label {
    color: rgba(0, 0, 0, 0.58);
}

.readonly input, .readonly {
    color: rgba(0, 0, 0, 0.58);
    cursor: default;
}

.vd-profile-wrap .other-detail .row:last-child .field-wrap {
    margin-bottom: 0;
}

.vd-profile-wrap .photo-side h4 {
    font-weight: 500;
    font-size: 22px;
    margin: 0;
}

/* style ends */

/* profile style start */
.profile-page .custom-card {
    padding: 20px 30px;
}

.profile-page .heading-wrap h1 {
    color: #214469;
    font-weight: 600;
}

.profile-page .pro-img {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    margin: 20px;
}

.profile-page h4 {
    font-weight: 600;
    font-size: 22px;
    color: #000000;
    margin-bottom: 40px;
}

.profile-page .field-wrap input {
    background: #f6f6f6;
    border: 0.5px solid rgba(33, 68, 105, 0.2);
    border-radius: 12px;
    padding-left: 50px;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 16px center;
}

.profile-page .field-wrap.mail input {
    background-image: url(assets/images/mail-border.png);
}

.profile-page .field-wrap.call input {
    background-image: url(assets/images/call-border.png);
}

.profile-page .field-wrap.location input {
    background-image: url(assets/images/location-border.png);
}

.profile-page .field-wrap.mbbs input {
    background-image: url(assets/images/mbbs.png);
}

.doc-field .doc-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.custom-card .doc-field {
    background: rgba(167, 174, 180, 0.14);
    padding: 24px 35px;
    width: 100%;
}

.custom-card .doc-field:first-child {
    margin-right: 30px;
}

.custom-card .doc-field:last-child {
    margin-left: 30px;
}

.profile-page .doc-head h4 {
    font-size: 25px;
    margin: 0;
}

.doc-field .doc-wrap img {
    width: 100%;
}

/* profile style ends */

/* custom-toggle-switch style start */
.switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e2e8ed;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    left: 0;
    bottom: -3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0px 0px 1px 0px black;
}

input:checked+.slider {
    background-color: #e2e8ed;
}

input:focus+.slider {
    box-shadow: 0 0 1px #e2e8ed;
}

input:checked+.slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    background: #005da8;
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.custom-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.custom-switch label.lbtext {
    padding-right: 40px;
}

/* switch ends */
.heading-wrap .right-wrap .custom-btn {
    width: 165px;
    margin-left: 20px;
}

.heading-wrap .right-wrap .custom-btn img {
    padding-right: 5px;
}

.check-select-wrap h5 {
    font-weight: 500;
    font-size: 20px;
    color: #121f48;
    margin: 0;
}

form.common-form.readonly input {
    border: 0;
    padding: 0;
    height: auto;
    font-weight: 500;
}

form.common-form.readonly .check-select-wrap h5 {
    margin-bottom: 10px;
}

form.border-fields .field-wrap select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(assets/images/select-arrow.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: calc(100% - 10px) center;
    padding-right: 30px;
}

/* common responsive start */
@media (min-width: 1400px) {
    .custom-dialog .modal-dialog.modal-md {
        max-width: 1000px;
    }
}

@media (max-width: 1600px) {
    .custom-btn {
        font-size: 16px;
    }

    /* table listing start */
    .table-wrap table th {
        font-size: 18px;
    }

    .table-wrap table td {
        font-size: 18px;
    }

    .heading-wrap h1 {
        font-size: 24px;
    }

    form.search-wrap input {
        font-size: 16px;
    }

    .pagination-wrap p {
        font-size: 16px;
    }

    .pagination-wrap ul .page-link {
        font-size: 16px;
    }

    .big-circle a img {
        width: 22px;
    }

    .custom-dialog .modal-body p {
        font-size: 17px;
    }

    .common-form .field-wrap input,
    .common-form .field-wrap select,
    .common-form .field-wrap textarea {
        font-size: 16px;
        height: 50px;
    }

    .common-form .field-wrap textarea {
        height: 130px;
    }

    .common-form .field-wrap label {
        font-size: 16px;
    }

    form.common-form .field-wrap {
        margin-bottom: 25px;
    }

    .vd-profile-wrap .img-wrap {
        width: 85px;
        height: 85px;
    }

    .vd-profile-wrap .photo-side h4 {
        font-size: 20px;
    }

    /* profile style start */
    .profile-page h4 {
        font-size: 20px;
        margin-bottom: 30px;
    }

    .profile-page .pro-img {
        margin: 20px 0;
    }

    .profile-page .doc-head h4 {
        font-size: 22px;
    }

    .custom-card .doc-field {
        padding: 25px;
    }

    /* profile style ends */

    .heading-wrap .right-wrap .custom-btn img {
        width: 24px;
    }

    form.border-fields .field-wrap select {
        background-size: 14px;
    }

    .createPatientDOB .MuiOutlinedInput-notchedOutline {
        height: 50px !important;
        margin-top: 4px !important;
    }

    .createPatientDOB .MuiInputBase-input {
        height: 49px !important;
    }

    .createPatientDOB .MuiOutlinedInput-root {
        height: 50px !important;
    }

    .multiselect .MuiOutlinedInput-root {
        height: 50px !important;
    }
}

@media (max-width: 1400px) {
    .table-wrap table td {
        font-size: 16px;
    }

    .custom-btn {
        font-size: 14px;
        padding: 10px 20px;
    }

    .custom-checkbox .checkmark {
        top: -3px;
    }

    /* table listing start */
    .heading-wrap h1 {
        font-size: 20px;
    }

    .heading-wrap form.search-wrap input {
        width: 400px;
    }

    form.search-wrap input {
        font-size: 14px;
    }

    .table-wrap table th {
        font-size: 14px;
        height: 50px;
    }

    .table-wrap table td {
        font-size: 15px;
        height: 60px;
    }

    .pagination-wrap p {
        font-size: 14px;
    }

    .pagination-wrap ul .page-link {
        font-size: 14px;
    }

    li.prev-arrow .page-link {
        background-size: 7px;
        padding-left: 0;
    }

    li.next-arrow .page-link {
        background-size: 7px;
        padding-right: 0;
    }

    .table-wrap table th:first-child,
    .table-wrap table td:first-child {
        padding-left: 20px;
    }

    .pagination-wrap {
        padding: 10px 20px;
    }

    .status-wrap {
        width: 110px;
    }

    /* end table */

    input[type="date"] {
        background-size: 16px;
    }

    .custom-dialog .modal-header .modal-title {
        font-size: 24px;
    }

    .custom-dialog .modal-body p {
        font-size: 15px;
    }


    .big-circle a {
        width: 40px;
        height: 40px;
    }

    .big-circle a img {
        width: 22px;
    }

    .common-form .field-wrap input,
    .common-form .field-wrap select,
    .common-form .field-wrap textarea {
        font-size: 14px;
        height: 54px !important;
    }

    .common-form .field-wrap textarea {
        height: 130px;
    }

    .common-form .field-wrap label {
        font-size: 14px;
        margin-bottom: 3px;
    }

    /*.body-outer .body-wrapper {
        width: calc(100% - 250px);
    }*/

    .check-select-wrap label.custom-checkbox {
        margin-top: 20px;
        margin-bottom: 38px !important;
    }

    .check-select-wrap {
        max-width: 650px;
    }

    .vd-profile-wrap .img-wrap {
        width: 70px;
        height: 70px;
    }

    .vd-profile-wrap .photo-side h4 {
        font-size: 18px;
    }

    /* profile style */
    .profile-page .doc-head h4 {
        font-size: 19px;
    }

    .doc-field .doc-head img {
        width: 15px;
    }

    .profile-page h4 {
        margin-bottom: 20px;
    }

    /* end profile style */
    .heading-wrap .right-wrap .custom-btn {
        width: 145px;
        margin-left: 12px;
    }

    .heading-wrap .right-wrap .custom-btn img {
        width: 20px;
    }

    form.border-fields .field-wrap select {
        background-size: 12px;
    }

    .body-outer .body-wrapper {
        width: calc(100% - 100px);
    }

    .createPatientDOB .MuiOutlinedInput-notchedOutline {
        height: 54px !important;
        margin-top: 4px !important;
    }

    .createPatientDOB .MuiOutlinedInput-root {
        /* height: 42px !important; */
    }

    .multiselect .MuiOutlinedInput-root {
        height: 42px !important;
    }
}

@media (max-width: 1200px) {
    .custom-dialog .modal-body {
        padding: 10px 24px 24px;
    }

    .custom-dialog .modal-header {
        padding: 10px 24px;
    }
}

@media (max-width: 991px) {
    .heading-wrap form.search-wrap input {
        width: 300px;
    }

    section.common-listing {
        padding: 20px 25px 20px 25px;
    }

    .table-wrap table th {
        font-size: 13px;
    }

    .table-wrap table td {
        font-size: 14px;
    }

    /* .body-outer .body-wrapper {
        width: calc(100% - 215px);
    } */

    /* togge slider start */
    .slider:before {
        height: 30px;
        width: 30px;
    }

    .switch {
        width: 70px;
        height: 24px;
    }

    .slider {
        width: 50px;
    }

    input:checked+.slider:before {
        transform: translateX(20px);
    }

    .profile-wrap {
        flex-direction: column;
    }

    .profile-wrap .detail-wrap {
        width: 97%;
    }

    /* toggle slider ends */
}

@media (max-width: 768px) {
    .body-outer .body-wrapper {
        width: 100%;
    }

    .pagination-wrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .pagination-wrap ul .page-link {
        width: 28px !important;
        height: 28px !important;
    }

    .pagination-wrap .page-item {
        margin-right: 5px;
    }

    li.prev-arrow .page-link,
    li.next-arrow .page-link {
        width: auto !important;
        height: auto !important;
    }

    .heading-wrap h1 {
        font-size: 18px;
    }

    /* dialog style */
    .custom-dialog .modal-body {
        padding: 10px 15px 20px;
    }

    .custom-dialog .modal-header {
        padding: 10px 15px;
    }

    /* end dialog style */

    .heading-wrap .right-wrap .custom-btn {
        margin: 10px 0 0;
    }

    form.common-form .btn-wrap button {
        max-width: fit-content;
    }

    form.common-form .col {
        width: 100%;
        flex: none;
    }

    .heading-wrap {
        justify-content: space-between;
        grid-gap: 15px 0;
    }

    .heading-wrap form.search-wrap {
        margin-left: auto;
    }

    .form-outer-grid {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .custom-dialog .modal-content .btn-wrap {
        justify-content: flex-end;
    }
}

.setPassword-section {
    padding-top: 134px !important;
    max-width: 800px;
    margin: auto;
}

.createPatientDOB .MuiFormControl-root {
    width: 100%;
}

.createPatientDOB .MuiInputBase-input {
    border: none !important;
}

.createPatientDOB .MuiOutlinedInput-root {
    height: 59px;
    border-radius: 10px !important;
}

.createPatientDOB .MuiOutlinedInput-notchedOutline legend {
    display: none !important;
}

.createPatientDOB .MuiOutlinedInput-notchedOutline {
    height: 60px;
    margin-top: 4px;
}

.createPatientDOB .MuiInputBase-input {
    height: 49px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #c7c7c7 !important;
}

.form-outer-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 1.5rem 0px;
    row-gap: 0rem;
}

.ml-auto {
    margin-left: auto !important;
}

@media (min-width: 1600px) {
    .multiselect .MuiOutlinedInput-root {
        height: 60px !important;
    }
}

/* patient-detail page style start */
.tabs-btn-wrap-blc .tab-content {
    position: static;
}

.tabs-btn-wrap-blc.tabs-btn-wrap .tab-wrap {
    width: 100%;
}

.tabs-btn-wrap-blc.tabs-btn-wrap .nav .nav-link {
    height: 100%;
    display: inline-flex;
    align-items: center;
}

.tabs-btn-wrap-blc.tabs-btn-wrap .btn-wrap {
    justify-content: unset;
    width: auto;
}

.tabs-btn-wrap-blc.tabs-btn-wrap .btn-wrap .custom-btn {
    margin-left: 6px;
}

.tabs-btn-wrap-blc.tabs-btn-wrap .nav .nav-item {
    flex: none;
}

/* patient-detail page style end */

/* Listing Filter Section Responisve Start */
@media (max-width: 1150px) {
    .filter-row-blc .filter-col {
        margin-bottom: 15px;
    }
}

@media (max-width: 550px) {
    .heading-wrap .filter-row-blc .filter-col {
        flex-direction: column;
        align-items: stretch !important;
        width: 100%;
    }

    .heading-wrap .filter-row-blc form.search-wrap {
        margin-left: unset;
        width: 100%;
        margin-right: 0;
        margin-bottom: 8px;
        padding-right: 0;
    }

    .heading-wrap .filter-row-blc .dropdown-filter-rw {
        padding-right: 0;
        margin: 0 -5px;
    }

    .heading-wrap .filter-row-blc .dropdown-filter-rw .field-wrap {
        flex: 1;
        padding: 0 5px;
    }

    .heading-wrap .filter-row-blc .btns-col {
        display: flex;
        margin-left: unset !important;
        flex: 1;
        margin: 0 -5px;
    }

    .heading-wrap .filter-row-blc .btns-col .custom-btn {
        flex: 1;
        margin: 0 5px;
    }
}

@media (max-width: 370px) {

    .heading-wrap .filter-row-blc .dropdown-filter-rw,
    .heading-wrap .filter-row-blc .btns-col {
        flex-direction: column;
        align-items: stretch !important;
    }

    .heading-wrap .filter-row-blc .dropdown-filter-rw .field-wrap,
    .heading-wrap .filter-row-blc .btns-col .custom-btn {
        margin: 0 0 5px 0;
    }

    .heading-wrap .filter-row-blc .dropdown-filter-rw .field-wrap:last-child,
    .heading-wrap .filter-row-blc .btns-col .custom-btn:last-child {
        margin: 0;
    }
}

/* Listing Filter Section Responisve End */

/* Patient Detail Page Responisve Start */
@media (max-width: 1155px) {
    .tabs-btn-wrap.tabs-btn-wrap-blc .tab-nav-blc {
        flex-direction: column;
        align-items: stretch !important;
    }

    .tabs-btn-wrap.tabs-btn-wrap-blc .tab-nav-blc>.nav {
        order: 1;
    }

    .tabs-btn-wrap.tabs-btn-wrap-blc .tab-nav-blc>.btn-wrap {
        margin-bottom: 6px;
    }

    .tabs-btn-wrap.tabs-btn-wrap-blc .tab-wrap {
        width: 100%;
        margin-bottom: 8px;
    }

    .tabs-btn-wrap.tabs-btn-wrap-blc .tab-wrap:last-child {
        margin-bottom: 0;
    }
}

.check-item {
    display: inline-flex !important;
    align-items: center;
    cursor: pointer;
}

.check-item input {
    width: 18px !important;
}

.check-item span {
    margin-left: 15px;
}

/* Patient Detail Page Responisve End */

.basic-multi-select {
    height: 42px;
}

.basic-multi-select .select__control {
    height: 100%;
    border-radius: 10px;
}

.basic-multi-select .select__control .select__input {
    height: 100% !important;
}

.common-form .input-srch-blc .field-wrap {
    margin-right: 15px;
}

.common-form .input-srch-blc .custom-btn {
    margin-top: 22px;
}

@media (max-width: 768px) {
    .body-outer {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .common-form .input-srch-blc {
        flex-direction: column;
    }

    .common-form .input-srch-blc .field-wrap {
        margin: 0 0 10px;
    }

    .common-form .input-srch-blc .custom-btn {
        margin-top: 0;
    }
}

.body-wrapper .back-btn {
    display: flex;
    margin: 0.5rem 1rem 0rem 1rem;
    padding: 0.5rem 0.5rem 0rem 0.5rem;
    cursor: pointer;
    width: fit-content;
}

@media (max-width: 991px) {
    .body-wrapper .back-btn {
        margin: 0;
        padding: 0.7rem;
    }
}

/* ----------------full calendar css-------------------- */
@media (max-width: 480px) {
    .fc .fc-toolbar {
        flex-direction: column;
        gap: 0.5rem;
    }
}

.fc .fc-col-header-cell-cushion,
.fc .fc-daygrid-day-number {
    color: #0055aa;
    text-decoration: none;
}

.fc table {
    border-radius: 0.5rem;
}

.fc table thead th div a {
    height: 4rem;
    background-color: rgba(0, 93, 168, 0.06);
    vertical-align: center;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.v-full-calendar__indicator {
    position: relative;
    margin-right: 12px;
    padding-left: 24px;
    line-height: 1.5;
}

.v-full-calendar__indicator,
.v-full-calendar__indicators {
    display: flex;
    align-items: center;
}

.v-full-calendar__indicator span {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    transform: translateY(-51%);
}

.fc .fc-daygrid-event-harness {
    cursor: pointer;
}

.basic-multi-select .select__menu .select__menu-list .select__option {
    text-transform: capitalize;
}

/* -----------custom over-write css for multi-selector starts------------- */
.select__value-container.select__value-container--is-multi.select__value-container--has-value {
    overflow-y: auto !important;
    height: 100% !important;
}

.MuiAccordionSummary-root {
    font-weight: 600 !important;
    background: rgba(0, 0, 0, 0.05) !important;
}

.Mui-expanded {
    min-height: 0 !important;
    margin: 12px 0 !important;
}

/* -----------custom over-write css for multi-selector ends------------- */
.questionnaire-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.questionnaire-list li {
    cursor: pointer !important;
    padding: 3px 0;
}

.questionnaire-list li:hover {
    background-color: rgb(211, 211, 211, 0.3);
}

.questionnaire-list li:not(:last-child) {
    border-bottom: 1px solid lightgrey !important;
}

.actions-styles {
    display: flex;
    justify-content: flex-end !important;
    /* width: 150px; */
    /* padding-right: 60px !important; */
}

.action-wrap-container {
    width: 100px;
    padding-right: 30px !important;
}

/* appointment modal */
.appointment-custom-modal .modal-dialog {
    max-width: 900px;
    width: 100%;
}

.btn-wrap-block {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin: 15px 0 0;
}

.btn-wrap-block button {
    padding: 5px 8px !important;
}

.xxl {
    width: auto;
    min-width: 1400px;
    max-width: 1700px;
}

.med {
    width: auto;
    min-width: 650px;
    max-width: 700px;
}

@media screen and (max-width: 767px) {
    .appointment-custom-modal .action-wrap {
        flex-direction: column;
    }
    
    .appointment-custom-modal .action-wrap button {
        margin: 0 0 15px;
        width: 100% !important;
    }
}
