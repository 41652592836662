.login-wrapper {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    height: 100%;
}

.login-wrapper .left-wrap {
    width: 50%;
    background-image: url(../assets/images/login-image.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
}

.login-wrapper .right-wrap {
    width: 50%;
}


html,
body {
    height: 100%;
}

div#root,
.App {
    height: 100%;
}

.login-wrapper .right-wrap .logo {
    margin-bottom: 80px;
    display: inline-block;
}

form .field-wrap label {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
}

form .field-wrap input {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    border: 0;
    background: transparent;
    padding: 5px 0 5px 15px;
    margin-left: 15px;
    border-left: 1px solid rgba(0, 0, 0, 0.34);
}

form .field-wrap input::placeholder {
    color: rgba(0, 0, 0, 0.37);
}

.form-wrap {
    width: 500px;
    margin: 0 auto;
}

.custom-form .page-link {
    display: flex;
    display: -ms-flexbox;
    justify-content: space-between;
    padding: 10px 0;
    margin-top: -15px;
    margin-bottom: 20px;
}

form.custom-form h4 {
    font-weight: 600;
    font-size: 36px;
    margin: 0;
    margin-bottom: 20px;
}

.page-link a {
    font-weight: 400;
    font-size: 18px;
    color: #0055AA;
    text-decoration: none;
}

.custom-form .field-wrap {
    margin-bottom: 30px;
}

form .field-wrap .input-icon {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    background: #F6F6F6;
    border-radius: 10px;
    padding: 18px 20px;
}

form .field-wrap input:focus {
    outline: 0;
}

/* only for forgot screens */
form.custom-form.forgot {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
}

form.custom-form .top-field p {
    font-weight: 400;
    font-size: 18px;
}


.section.login-wrapper input.form-control.f-input.undefined {
    border: none;
    background-color: #F6F6F6;
}


/* responsive start */
@media (max-width: 1600px) {
    form.custom-form h4 {
        font-size: 30px;
    }

    .login-wrapper .right-wrap .logo {
        margin-bottom: 50px;
    }

    form .field-wrap label {
        font-size: 16px;
        margin-bottom: 5px;
    }

    form .field-wrap .input-icon {
        padding: 12px 15px;
    }

    .custom-form .field-wrap {
        margin-bottom: 20px;
    }

    form .field-wrap input {
        font-size: 16px;
    }

    .page-link a {
        font-size: 16px;
    }

    form.custom-form .top-field p {
        font-size: 16px;
    }

}

@media (max-width: 1400px) {
    .right-wrap .logo img {
        width: 220px;
    }

    .form-wrap {
        width: 400px;
    }

    form .field-wrap label {
        font-size: 15px;
    }

    form .field-wrap input {
        font-size: 15px;
    }

    form .field-wrap .input-icon {
        padding: 8px 15px;
    }

    .page-link a {
        font-size: 15px;
    }

    form.custom-form h4 {
        font-size: 26px;
        margin-bottom: 15px;
    }

    form.custom-form .top-field p {
        font-size: 15px;
    }
}


@media (max-width: 1200px) {}

@media (max-width: 991px) {
    .form-wrap {
        width: 300px;
    }

    form .field-wrap input {
        padding: 3px 0 3px 10px;
        margin-left: 10px;
    }

    form .field-wrap .input-icon {
        padding: 8px 10px;
    }

    .page-link a {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .login-wrapper {
        display: block;
    }

    .login-wrapper .right-wrap {
        width: 100%;
    }

    .login-wrapper .left-wrap {
        width: 100%;
        height: 400px;
    }

    .form-wrap {
        width: 100%;
        padding: 40px 15px;
    }
}