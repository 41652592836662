#encounterSearchLabel {
    font-size: 18px;
    font-weight: 400;
    color: inherit;
    margin-bottom: 10px;
}

.encounterSearch{
    margin : 4px 10px;
}

.encounterSearch input{
    border: 1px;
}

form.common-form .btn-wrap  {
    margin-top: 40px;
}