.view-service-request-container {
    margin: 0 auto;
    border: 1px solid #ccc;
    padding: 10px 10px 20px 10px;
  }
  
  .table-container {
    width: 100%;
    border: 1px solid #ccc;
  }
  
  .table-heading {
    background-color: #0083c0;
    color: white;
    text-align: center;
    padding: 10px;
    margin: 0;
  }
  
  .table-wrapper {
    display: table;
    width: 100%;
  }
  
  .table-row {
    display: inline-flex;
    flex-direction: column;
    width: 25%;
  }
  
  .table-cell {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 8px;
    height: 39px;
    text-align: center;
    text-align: left;
    font-size: 13px;
  }
  
  .highlight {
    background-color: #f0f6fa;
    /* You can set the highlight color to your preference */
    font-weight: bold;
    /* Optional: Make the text bold in the highlighted cells */
  }
  
  .sign-wrapper {
    display: flex;
    width: 25%;
    justify-content: space-between;
    margin-top: 54px;
    align-items: flex-start;
    /* margin-left: 10px; */
  }
  .note-container {
    padding: 10px;
    margin-top: 11px;
  }

  .note-wrapper{
    border: 1px solid rgba(0, 0, 0, 0.6) !important;;
    padding: 10px;
    border-radius: 10px;
  }
  
  .btn-box {
    margin-top: 10px;
  }
  
  .info-wrapper span {
    color: #07384e;
  }

  .info-item{
    margin-left: 20px;
  }

  .abbreviate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
  }

  .dataBox img {
    vertical-align: middle;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}
  
  .sign-wrapper p {
    color: #07384e;
  }
  
  .c-black-8 {
    color: rgba(0, 0, 0, 0.8) !important;
  }
  
  .c-black-7 {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  
  .c-black-6 {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  
  .c-black-5 {
    color: rgba(0, 0, 0, 0.5) !important;
  }
  
  .m-gap {
    gap: 20px;
  }
  
  .customCheckBox {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 12px;
  }
  
  .form-check-input:disabled {
    border-color: black;
  }
  
  .form-check-input:disabled {
    opacity: 1 !important;
  }
  
  .white-space {
    white-space: nowrap;
  }