.custom-form-sft .field-wrap input {
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    border: 0;
    background: #f6f6f6;
    padding: 15px;
    margin: 0px;
   
}

.custom-form-sft  .field-wrap {
    margin-bottom: 40px;
}
.download-button .custom-btn{
    display: inline;
    margin-right: 20px;
}