.dashboard-encounter-list {
    margin: 20px;
  }
  
  .date-selector-wrap {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .table-wrap {
    margin-top: 20px;
  }
  