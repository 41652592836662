.filter-row-blc {
    width: 100%;
}

.filter-row-blc .filter-col {
    padding-right: 2px;
}

.heading-wrap .filter-row-blc .search-wrap {
    width: 280px;
    margin: 0;
    padding-right: 10px;
    /* padding-right: 10px; */
}

.filter-row-blc .search-wrap .form-control {
    width: 100%;
}

.filter-row-blc .dropdown-filter-rw {
    padding-right: 10px;
}

.filter-row-blc .dropdown-filter-rw .field-wrap {
    padding-right: 10px;
    margin: 0;
}

.filter-row-blc .btns-col .custom-btn {
    margin-left: 10px;
}

.action-wrap.user a, .action-wrap.user div{
    background-color: transparent;
}
.action-wrap.user a:hover, .action-wrap.user div:hover{
    background-color: #a9cdeb;;
}

.userAccount .customCheckBox{
    margin-bottom: 0px;
}
.editUserModal .row:nth-child(2) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.14);
    margin-bottom: 15px;
}

/* form.common-form.border-fields.userAccount .field-wrap input,
form.common-form.border-fields.userAccount .field-wrap select,
form.common-form.border-fields.userAccount .multiselect .MuiOutlinedInput-root,
form.common-form.border-fields.userAccount .react-date-picker-wrapper .react-date-picker{
    background-color: #f6f6f6;
} */

.userAccount  .css-reqq1a-control, .userAccount .css-1hcefyi-control{
    background-color: #f6f6f6;
    min-height: 60px;
}
.userAccount .css-1jqq78o-placeholder{
    padding-bottom: 25px;
}

